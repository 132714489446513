import { 
ALL_USERS
} 
from '../../constants/constants';

const INIT_STATE = {
    users_list:[],

}

const usersReducer= (state = INIT_STATE, action) => {
    switch(action.type) {
        case ALL_USERS:
            return {
                ...state,
                users_list: action.payload
            }

        default:
            return state;
    }
}

export default usersReducer;