import { 
    ALL_PAGE_DATA
} 
from '../../constants/constants';

const INIT_STATE = {
    all_data:[],

}

const contentReducer= (state = INIT_STATE, action) => {
    switch(action.type) {
        case ALL_PAGE_DATA:
            return {
                ...state,
                all_data: action.payload
            }

        default:
            return state;
    }
}

export default contentReducer;