import { } 
from '../../constants/constants';

const INIT_STATE = {

}

const authReducer= (state = INIT_STATE, action) => {
    switch(action.type) {
        // case LANDING_PAGE:
        //     return {
        //         ...state,
        //         all_landlords: action.payload
        //     }

        default:
            return state;
    }
}

export default authReducer;