// END POINTS
//export const BASE_URL = "https://backend.inspiratiekaartwarmtezonering.be";
export const BASE_URL = "https://backend.ww.production.vvsgkube.agifly.cloud";

// export const BASE_URL = "http://localhost:4000";

// AUTH
export const USER_LOGIN = 'USER_LOGIN';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const TOKEN_EXPIRE = 'TOKEN_EXPIRE';
export const USER_VIEW = 'TUSER_VIEW';

// FRONTEND CONTENT
export const ALL_PAGE_DATA  = "ALL_PAGE_DATA";

// DOMAINS
export const ALL_DOMAINS  = "ALL_DOMAINS";

//USERS
export const ALL_USERS  = "ALL_USERS";

//ZONING PLAN
export const ALL_ZONING_PLANS  = "ALL_ZONING_PLANS";

// LOADER
export const SHOW_ERROR_MESSAGE    = "SHOW_ERROR_MESSAGE ";
export const SHOW_SUCCESS_MESSAGE  = "SHOW_SUCCESS_MESSAGE";
export const SHOW_LOADER  = "SHOW_LOADER";
export const HIDE_LOADER  = "HIDE_LOADER";
export const HIDE_MESSAGE = "HIDE_MESSAGE";
