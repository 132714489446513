// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import customizer from "./customizer/";
import authReducer from "./admin/auth";
import usersReducer from './admin/users';
import domainsReducer from './admin/domains';
import contentReducer from './admin/content'
import loader from "./loader/loader";
import zoningReducer from "./admin/zoning";
import { reducer as toastrReducer } from "react-redux-toastr";

const rootReducer = combineReducers({
   auth:authReducer,
   content:contentReducer,
   users:usersReducer,
   domains:domainsReducer,
   toastr: toastrReducer, // <- Mounted at toastr.
   customizer: customizer,
   loader:loader,
   zoning:zoningReducer
});

export default rootReducer;
