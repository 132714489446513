import { 
    ALL_ZONING_PLANS
} 
from '../../constants/constants';
    
    const INIT_STATE = {
        zoning_list:[],
    
    }
    
    const zoningReducer= (state = INIT_STATE, action) => {
        switch(action.type) {
            case ALL_ZONING_PLANS:
                return {
                    ...state,
                    zoning_list: action.payload
                }
    
            default:
                return state;
        }
    }
    
export default zoningReducer;