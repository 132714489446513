import {
ALL_DOMAINS
 } 
from '../../constants/constants';

const INIT_STATE = {
    domains_list:[],

}

const domainsReducer= (state = INIT_STATE, action) => {
    switch(action.type) {
        case ALL_DOMAINS:
            return {
                ...state,
                domains_list: action.payload
            }

        default:
            return state;
    }
}

export default domainsReducer;